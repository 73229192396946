.item.no-pointer {
  cursor: default !important;
}

.nested-modal {
  z-index: 9999 !important;
}

.family-label-hovered {
  background-color: #b99c6d !important;
}

.highlight-once {
  animation: highlight-once 1s ease-in-out infinite;
  background-color: #ffeaa7;
}

@keyframes highlight-once {
  0% {
    background-color: #ffeaa7;
  }
  50% {
    background-color: #ffd59a;
  }
  100% {
    background-color: #ffeaa7;
  }
}
