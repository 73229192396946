.text-error {
  color: red;
}

body {
  padding-top: 53px;
}

@media print {
  body {
    padding-top: 0 !important;
  }
}
