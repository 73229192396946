.inv-font {
  font-family: "Raleway", sans-serif;
}

.test-header {
  font-weight: 600 !important;
  font-size: 16px;
}

.item.no-before::before {
  display: none !important;
}

.item.new-version-pointer {
  align-items: center;
  font-style: italic;
  animation: bounce-right 1s ease-in-out infinite;
}

@keyframes bounce-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}

#new-version-header-effect {
  animation: color-transition 1.5s ease-in-out infinite;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

@keyframes color-transition {
  0% {
    color: #fff;
  }
  50% {
    color: gold;
  }
  100% {
    color: #fff;
  }
}
