.side-bar {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  border-left: 1px solid;
  border-radius: 0;
  border-color: rgba(64, 194, 133, 0.693);
  background-color: rgb(255, 255, 255);
  transition: 0.8s ease;
  position: absolute;
  z-index: 100;
  right: 0;
}

.toggle-menu {
  height: 50px;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 9rem;
  width: 10px;
  position: relative;
  outline: none;
  z-index: 100;
  background-color: rgba(64, 194, 133, 0.693);
  border-color: rgba(64, 194, 133, 0.693);
  border-right: 0;
  /*right: 315px;*/
}

.small-icon {
  height: 16px;
}
