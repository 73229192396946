table.dataTable.table thead th.sorting:after,
table.dataTable.table thead th.sorting:before {
  position: absolute;
  right: 8px;
}

/* Removes sort icon from duplicated datatable header element */
.without-sort-icon::after,
.without-sort-icon::before {
  content: none !important;
}

table.dataTable.table thead th.sorting:before {
  top: 12px;
}

table.dataTable.table thead th.sorting:after {
  top: 19px;
}

table.dataTable th p {
  white-space: break-spaces;
}

table.dataTable td {
  padding: 3px 10px;
  width: 1px;
  white-space: nowrap;
}

table.dataTable {
  border-collapse: collapse !important;
}

table.dataTable.table thead th.sorting {
  padding-right: 12px !important;
}

table.dataTable tbody tr.contextmenu {
  outline: medium solid #8ac3e6 !important;
}

.without-after-element::after {
  content: none !important;
}

.DTE_Action_Edit input {
  width: 100% !important;
}

td.details-control {
  background: url("https://www.datatables.net/examples/resources/details_open.png")
    no-repeat center center;
  cursor: pointer;
}
tr.shown td.details-control {
  background: url("https://www.datatables.net/examples/resources/details_close.png")
    no-repeat center center;
}

table.dataTable tbody tr.selected > * {
  box-shadow: inset 0 0 0 9999px #8ac3e6 !important;
}
/* Changes Odd Row Color  */

table.dataTable tbody tr.odd {
  background-color: #e8e8e8;
}

.dt-align-vertical {
  display: "flex";
  align-items: center;
}
/* #a1a09f */
