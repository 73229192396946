.hover-darken:hover {
  background-color: #c9c9c9;
  cursor: pointer;
}

.selected {
  background-color: #c9c9c9;
}

.scrollable-tb-row {
  overflow-x: auto;
  max-width: 350px;
}

/* fix for centering full screen semantic UI modal*/
.ui.fullscreen.scrolling.modal {
  left: 2.5% !important;
}

.ui.fullscreen.modal {
  left: 2.5% !important;
}
