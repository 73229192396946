.react-datepicker__month-container {
  min-width: 500px;
}
.react-datepicker__month {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  min-height: 200px;
  padding: 12px;
}

.react-datepicker__current-month {
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px !important;
}

.react-datepicker__day-names {
  padding: 16px 12px;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day {
  text-align: center;
  font-size: 14px;
  width: 35px !important;
  line-height: 35px !important;
  margin: 3px;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}
