.mt-2 {
  margin-top: 0.8rem;
}

.text-red {
  color: rgb(212, 25, 25);
}

.light-hover:hover {
  filter: brightness(1.2);
  cursor: help;
}

input[type="file"] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.custom-file-upload {
  text-align: center;
  border-radius: 0.2rem;
  width: 100%;
  color: rgb(38, 38, 38);
  background-color: #dedede;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
