.fade-in-right {
  opacity: 0;
  animation: fade-in-right 0.7s ease-in-out forwards;
}

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translate(50%, 0);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
