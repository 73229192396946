.grid-box {
  border: 1px hidden !important;
  border-radius: 10px !important;
  box-shadow: 3px 5px 10px #888888 !important;
}

.ui.fluid.container {
    margin-left: 20px !important;
    margin-right: 50px !important;
}

.upc-container {
  background-color: #A9A9A9 !important;
}