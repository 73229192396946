.label-outer {
  width: 300px;
  margin: auto;
  text-align: center;
}

.label-inner {
  width: 305px;
  margin: auto;
  border: 1px solid black;
  padding: 10px;
}

.cut {
  border: none;
  border-top: 3px dashed rgb(54, 53, 53);
}
@media print {
  .page-break {
    display: block;
    break-before: page;
  }
}
