.ui.labeled.icon.button:hover {
  background-color: gray !important;
}

.grid-box {
  border: 1px hidden !important;
  border-radius: 10px !important;
  box-shadow: 3px 5px 10px #888888 !important;
  border-color: grey !important;
}

.loadingIcon{
  margin: auto;
  width: 60%;
  border: none;
  padding: 10px;
}